.Notifications {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 11;
}

.Notification {
  position: relative;
  width: 315px;
  margin: 0 0 8px;
  padding: 10px 48px 15px 54px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.Notification:hover .NotificationClose { opacity: 1; }

.NotificationTitle {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.NotificationMessage {
  margin: 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.5;
}

.NotificationClose {
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
  color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.NotificationIcon {
  position: absolute;
  top: 16px;
  left: 16px;
}

/* CSSTransitionGroup Animations */
.Notification-enter {
  opacity: 0.01;
}
.Notification-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.Notification-exit {
  opacity: 1;
}
.Notification-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.Home__footer {
  display: flex;
  flex-direction: row;
  padding: 0 4.5rem 2vw;
  color: #989fb2;
  font-size: 0.9em;
}

.Home__footer__contact {
  flex: 1;
}

.Home__footer__email {
  color: #1f5eed;
  text-decoration: none;
}

.Home__footer__links a {
  color: #1f5eed;
  text-decoration: none;
}

@media screen and (max-width: 630px) {
  .Home__footer {
    flex-direction: column;
    line-height: 1.9;
    text-align: center;
  }
}

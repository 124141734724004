@keyframes LoadingSpinner {
  to {
    transform: rotate(360deg);
  }
}

.Loading-spinner__container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.Loading-spinner--with-header {
  height: calc(100vh - 140px);
}

.Loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #dadee9;
  border-radius: 50%;
  border-top-color: #1f5eed;
  animation: LoadingSpinner 0.6s linear infinite;
  content: '';
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease-out;
}

.Loading-spinner--visible {
  opacity: 1;
}

.StockList {
  margin: 4vh 0 4vh;
  padding: 0;
  border: 0 none;
  border-spacing: 0;
  text-align: left;
  cursor: default;
}

@media (min-width: 1300px) {
  .StockList {
    font-size: 1em;
  }
}

@media (min-width: 1900px) {
  .StockList {
    font-size: 1.2em;
  }
}

.StockList-header > * {
  padding: 1.5em 3rem 1em 0;
  color: #1f5eed;
  font-size: 0.8em;
  font-weight: 400;
}

.StockList .StockList-cell,
.Stocklist-footer > * {
  padding: 0.6em 3rem 0.6em 0;
}

@media (min-width: 1300px) {
  .StockList .StockList-cell,
  .StockList th {
    padding-right: 4rem;
  }
}

@media (min-width: 1700px) {
  .StockList .StockList-cell,
  .StockList th {
    padding-right: 5rem;
  }
}

.StockList-item {
  cursor: default;
  transition: background-color 0.1s ease-in-out;
}

.StockList-item:hover {
  background: #f1f5fe;
}

.Stocklist-item--dropdown {
  cursor: pointer;
}

.StockList-item .StockList-grey {
  padding-right: 0;
  transition: background-color 0.1s ease-in-out;
}

.StockList-item:hover .StockList-grey {
  background: #e9effa;
}

.StockList-cell.StockList-symbol {
  max-width: 90px;
  padding-right: 1.5rem;
  overflow: hidden;
  font-size: 0.85em;
  letter-spacing: 0.06em;
  text-overflow: ellipsis;
}

.StockList-cell.StockList-symbol,
.StockList th:first-child {
  padding-left: 4.5rem;
}

.StockList-cell__Delete {
  margin-left: 12px;
  padding: 0;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  background: transparent;
  opacity: 0;
  transition: opacity 0.1s ease-in;
}

.StockList-transactions:hover .StockList-cell__Delete {
  opacity: 0.5;
}

.StockList-cell__Delete:hover {
  cursor: pointer;
}

@media (min-width: 1700px) {
  .StockList-cell.StockList-symbol,
  .StockList th:first-child {
    padding-right: 2.5rem;
    padding-left: 10rem;
  }
}

.StockList-name {
  /* force name to use all available width of table */
  width: 99%;
}

.Stocklist-item--dropdown > .StockList-name:before {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 0;
  height: 0;
  margin-right: 0.7rem;
  border-width: 0.4rem 0 0.4rem 0.5rem;
  border-style: solid;
  border-color: transparent transparent transparent #007bff;
  content: '';
  transition: transform 0.16s ease-out;
}

.Stocklist-item__expanded > .StockList-name:before {
  transform: rotate(90deg);
}

.StockList-item .StockList-name {
  /* needed for ellipsis to work: */
  max-width: 0;
  overflow: hidden;
  font-size: 1.05em;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.3;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.StockList-avg-price,
.StockList-quantity,
.StockList-book-value,
.StockList-market-value,
.StockList-change,
.StockList-all-total {
  -moz-font-feature-settings: 'tnum';
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.02em;
  text-align: right;
  white-space: nowrap;
}

.StockList .StockList-grey {
  position: relative;
  min-width: 100px;
  padding-right: 4rem;
  padding-left: 2.5em;
  background: #f7f9fb;
}

th.StockList-grey::before {
  position: absolute;
  top: calc(-100px + 1.5em);
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, white, #f7f9fb);
  content: '';
}

.StockList .StockList-all-total {
  padding-top: 1.2em;
  font-weight: 600;
}

@media (min-width: 1700px) {
  .StockList .StockList-grey {
    padding-right: 10rem;
  }
}

.StockList-grey-bg {
  position: fixed;
  width: 700px;
  height: 999em;
  margin-left: -2.5em;
  background: #f7f9fb;
  z-index: -1;
}

.Stocklist__empty {
  margin-top: 20vh;
  color: #b6c2cf;
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.Stocklist__empty-text {
  margin: 0.6em auto 1em;
}

.StockList__pill {
  display: inline-block;
  margin-left: 0.8em;
  padding: 0.2em 0.5em 0.3em;
  border-radius: 0.5em;
  background: rgba(0, 189, 88, 0.18);
  color: #00803a;
  font-size: 0.8em;
  font-weight: 500;
}

.StockList__pill--negative {
  background: rgba(250, 42, 88, 0.15);
  color: #fa2a58;
}

.StockList-transactions {
  font-size: 1rem;
}

.StockList-transactions > * {
  padding: 0.65em 3rem 0.65em 0;
}

.StockList-transactions .StockList-name {
  padding-left: 1.2rem;
}

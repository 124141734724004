.Header {
  display: flex;
  position: relative;
  align-items: center;
  height: 12vh;
  min-height: 90px;
  max-height: 140px;
  margin: 0 auto;
  padding: 0 4rem;
  cursor: default;
  z-index: 10;
}

.Header-title {
  margin: 0;
  font-size: 1.2em;
}

.Header-menu {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-left: 200px;
}

.Header-buttons {
  min-width: 200px;
}

.Header-menu ul {
  display: flex;
  flex-grow: 0;
  margin: 0 2em 0 1em;
  padding: 0;
  border-radius: 8px;
  background: #e2ebff;
  list-style-type: none;
}

.Header-menu li {
  margin: 0.3em;
}

.Header-menu a {
  fill: currentColor;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 0.7em;
  border-radius: 6px;
  color: #1f5eed;
  font-size: 0.9em;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 1300px) {
  .Header-menu a {
    font-size: 0.95em;
  }
}

.Header-menu-icon {
  margin-right: 7px;
  margin-left: 3px;
}

.Header-menu [data-active='true'] {
  background: white;
  box-shadow: 0 5px 9px rgba(10, 59, 170, 0.12);
  cursor: default;
}

.Header-edit-holdings {
  margin-left: 0.6em;
  padding: 0 1.5em;
}

.Header__user {
  display: flex;
  position: relative;
  align-items: center;
  margin-right: -20px;
  margin-left: 20px;
  padding: 10px;
}

.Header__user-popup {
  position: absolute;
  top: 60px;
  right: -15px;
  min-width: 230px;
  transform: translateY(-10px);
  border-radius: 4px;
  background: white;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.Header__user-popup--padded {
  padding: 16px 0 22px;
}

.Header__user-arrow {
  transition: transform 0.2s ease-in-out;
}

.Header__user:focus {
  outline: none;
}

.Header__user:hover {
  cursor: pointer;
}

.Header__user-popup {
  cursor: default;
}

.Header__user:focus .Header__user-arrow,
.Header__user:hover .Header__user-arrow {
  transform: rotate(180deg);
}

.Header__user:focus .Header__user-popup,
.Header__user:hover .Header__user-popup {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.Header__user-popup-text {
  margin: 0 16px 0.5em;
  white-space: nowrap;
}

.Header__user-popup-section {
  margin: 22px 0;
  padding: 16px 16px 20px;
  border-top: solid 1px #f0f0f0;
  border-bottom: solid 1px #f0f0f0;
  background: #f9f9fa;
  cursor: pointer;
}

.Header__user-popup-list-item {
  padding: 16px 16px 20px;
  border-bottom: solid 1px #f0f0f0;
  cursor: pointer;
}

.Header__user-popup-list-item:last-child {
  border-bottom: 0 none;
}

.Header__user-popup-list-item-active {
  background: #f0f5ff;
  font-weight: bold;
}

.Header__user-popup-title {
  margin: 0 0 8px;
  font-size: 0.6em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.Header__user-logout {
  width: calc(100% - 32px);
  margin: 0 16px 0;
}

.Header__user-img {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
}

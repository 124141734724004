.Graph-wrapper {
  position: relative;
  height: 40vh;
  min-height: 300px;
  margin: 0 calc(4rem - 5px);
  z-index: 1;
}

.Graph-tooltip {
  padding: 10px 18px 10px 12px;
  border: 0;
  border-radius: 8px;
  background: white;
  color: #1f5eed;
  font-size: 0.85em;
  line-height: 1.6;
  box-shadow: 2px 12px 28px rgba(10, 0, 50, 0.08);
}

.Graph-tooltip-header {
  margin: 0 -6px 8px 0;
  padding-bottom: 6px;
  border-bottom: solid 1px #ddd;
  color: #111;
  font-size: 1em;
}

.Graph-placeholder {
  width: 100%;
}

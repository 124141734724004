.Modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
  cursor: default;
  transition: opacity 0.2s ease-in-out;
  z-index: 20;
}

.Modal-fade-enter .Modal__overlay {
  opacity: 0;
}
.Modal-fade-enter-active .Modal__overlay {
  opacity: 1;
}
.Modal-fade-exit .Modal__overlay {
  opacity: 1;
}
.Modal-fade-exit-active .Modal__overlay {
  opacity: 0;
}

.Modal {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 680px;
  margin: 0 auto;
  padding: 1.8rem 2.5rem 2.5rem;
  transform-origin: top center;
  border-radius: 0 0 10px 10px;
  background: white;
  box-shadow: 3px 25px 20px rgba(0, 0, 0, 0.08);
  cursor: default;
  transition: opacity 0.2s ease-in-out, transform 0.15s ease-in-out;
  z-index: 21;
}

.Modal-fade-enter .Modal {
  transform: translateY(-50px) scale(0.95);
  opacity: 0;
}
.Modal-fade-enter-active .Modal {
  transform: none;
  opacity: 1;
}
.Modal-fade-exit .Modal {
  transform: none;
  opacity: 1;
}
.Modal-fade-exit-active .Modal {
  transform: translateY(-50px) scale(0.95);
  opacity: 0;
}

.Modal__close {
  position: absolute;
  top: 1.8rem;
  right: 2.5rem;
  margin-top: -20px;
  margin-right: -25px;
  padding: 25px;
  color: #1f5eed;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;
}

.Modal__close:hover {
  text-decoration: underline;
}

.Modal__title {
  margin: 0 0 1.2em 0;
  color: #1f5eed;
  font-size: 1.3em;
}

.Modal__input-label {
  flex: 1;
  margin: 0 0.7em;
  font-size: 0.85em;
}

.Modal__input-label:first-child {
  margin-left: 0;
}

.Modal__input-label:last-child {
  margin-right: 0;
}

.Modal__row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.Modal__input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 54px;
  margin: 0.6rem 0 1.8rem;
  padding: 0 1rem;
  border: solid 1px #caced9;
  border-radius: 3px;
  background: white;
  color: #111;
  font-size: 1.2rem;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

.Modal__input::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.Modal__input:hover {
  border-color: #aaa;
}

.Modal__input:focus {
  border-color: #1f5eed;
  outline: none;
  background: #f5f8ff;
}

.Modal__input:invalid {
  border-color: red;
}

.Modal__date {
  margin-bottom: 0;
}

.DayPickerInput-Overlay {
  margin-top: 2px;
  border-radius: 6px;
  box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.1);
}

.Modal__date-container .DayPicker-Day--today {
  color: #111;
}

.Modal__date-container
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background: #1f5eed;
}

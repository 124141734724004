.Loading-placeholder {
  display: inline-block;
  position: relative;
  width: 70%;
  height: 16px;
  overflow: hidden;
  border-radius: 2px;
  background: rgba(184,199,214,0.15);
}

@keyframes placeholder {
  100% {
    transform: translateX(100%);
  }
}

.Loading-placeholder:after {
  position: absolute;
  width: 300px;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(255,255,255,0.5), rgba(0, 0, 0, 0));
  animation: placeholder 1.5s infinite;
  animation-timing-function: ease-in-out;
  content: '';
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.Modal__search {
  position: relative;
}

.Modal__search--invalid .Modal__input {
  border-color: red;
}

.Modal__search--invalid .Modal__input:focus {
  border-color: #1f5eed;
}

.Modal__search:before {
  box-sizing: border-box;
  position: absolute;
  top: 16px;
  right: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid #dadee9;
  border-radius: 50%;
  border-top-color: #1f5eed;
  content: '';
  opacity: 0;
  pointer-events: none;
  z-index: 2;
}

.Modal__search--loading.Modal__search:before {
  animation: spinner 0.6s linear infinite;
  opacity: 1;
}

.Modal__search .react-autosuggest__suggestions-container {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  border-radius: 0 0 6px 6px;
  background: white;
  box-shadow: 1px 8px 15px rgba(0, 0, 0, 0.09);
  z-index: 3;
}

.Modal__search .react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
}

.Modal__search .react-autosuggest__suggestion {
  padding: 0 1rem;
  line-height: 30px;
  cursor: pointer;
}

.Modal__search .react-autosuggest__suggestion:hover {
  background: #f1f5fe;
}

.Modal__suggestion-right {
  float: right;
  font-size: 0.8em;
  opacity: 0.5;
}

.App {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #111;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 1.125em;
}

.App__body {
  min-height: 100vh;
  transition: filter 0.2s ease-in;
}

.App--signedIn {
  min-width: 900px;
}

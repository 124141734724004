.Button {
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  padding: 0 1.2em;
  border: none;
  border-radius: 5px;
  background: #1f5eed;
  color: white;
  font-family: inherit;
  font-size: 0.9em;
  font-weight: bold;
  line-height: 40px;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.1s ease-in, background-color 0.1s ease-in,
    transform 0.1s ease-in;
}

.Button[disabled] {
  color: rgba(255, 255, 255, 0.6);
  opacity: 0.7;
  pointer-events: none;
}

.Button:hover {
  background: #0f4edd;
}

.Button:focus,
.Button:active {
  outline: none;
  background: #002ebd;
  color: #e8efff;
  transition: all 0.1s ease-out;
}

.Button:active {
  transform: scale(0.95);
}

.Button-secondary {
  border: solid 1px #141337;
  background: transparent;
  color: #131236;
}

.Button-secondary:hover {
  border-color: #0f4edd;
  background: transparent;
  color: #0f4edd;
}

.Button-secondary:active,
.Button-secondary:focus {
  border-color: #002ebd;
  background: rgba(30, 94, 237, 0.06);
  color: #002ebd;
}

.Button--full-width {
  width: 100%;
  text-align: center;
}

.Button--xl {
  height: 46px;
  font-size: 1em;
  line-height: 44px;
}

.Home {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #131236;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1em;
  line-height: 1.2;
}

.Home__logo {
  height: 35px;
}

.Home__hero {
  background-color: #f4f7fe;
}

.Home__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3vw 4vw;
}

.Home__getstarted {
  margin-left: auto;
}

.Home__container {
  max-width: 28em;
  margin: auto;
  padding: 10px 20px 60px;
  font-size: 1.3em;
  text-align: center;
}

.Home__title {
  margin-bottom: 0.5em;
  font-size: 2.5em;
  font-weight: 800;
  line-height: 1.1;
}

.Home__subtitle {
  width: 15em;
  margin: 0 auto;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.4;
}

.Home__subtitle__extra {
  margin-top: 1em;
  color: #1f5eed;
  font-size: 0.85em;
}

.Home__body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 0 4vw;
  background-image: url(./home-background-shape.svg);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
}

.Home__bullets {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 15.5em;
  padding-bottom: 6em;
  padding-left: 0;
  list-style-type: none;
}

.Home__bullets__item {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  line-height: 1.4;
}

.Home__bullets__item p {
  flex: 1;
}

.Home__checkmark {
  position: relative;
  top: 2px;
  margin-right: 16px;
}

.Home__preview {
  flex: 3;
  align-items: flex-start;
  max-width: 942px;
  overflow: hidden;
  perspective: 150;
  perspective-origin: 50% 75%;
}

.Home__preview img {
  width: 100%;
  transform: rotateY(-1deg);
}

@media screen and (max-width: 950px) {
  .Home__container {
    padding-bottom: 150px;
  }
  .Home__bullets {
    margin-top: -90px;
    padding-left: 20px;
  }
  .Home__preview {
    position: relative;
    flex: 1;
    height: 650px;
    margin-top: -90px;
    overflow: hidden;
  }
  .Home__preview img {
    position: absolute;
    width: 942px;
  }
}

@media screen and (max-width: 630px) {
  .Home {
    font-size: 1em;
  }
  .Home__container {
    padding-bottom: 80px;
    font-size: 1.1em;
  }
  .Home__bullets {
    margin: 0 auto;
  }
  .Home__preview {
    display: none;
  }
}
